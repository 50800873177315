import { isUuid } from "~/lib/regex";
import { SHIPPING_METHODS } from "~/lib/constants";

export const useInitialShippingState = async () => {
	const route = useRoute();
	const { query } = route;
	const { pickup } = query as { pickup: string | null };

	if (!pickup) {
		return;
	}

	const { setShippingMethod } = useShopwareSession();
	if (pickup === "delivery") {
		await setShippingMethod(SHIPPING_METHODS.delivery.shopwareId);
		return;
	}

	// set shipping method to pickup
	await setShippingMethod(SHIPPING_METHODS.pickup.shopwareId);

	if (!isUuid(pickup)) {
		return;
	}

	// set pickupLocationId to cart extension
	const { updateExtension } = useShopwareCart();
	await updateExtension({
		pickupLocationId: pickup,
		deliveryTimeSlot: null
	});
};
